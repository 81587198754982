import React, { useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import { Tabs, Modal, Collapse, Popconfirm, message, Dropdown, Skeleton, Layout, Menu, Tree, Input, Row, Col, Button, Checkbox, Space, AutoComplete, Tag, Badge, Affix, Switch } from "antd";
import { ArrowsAltOutlined, ShrinkOutlined, RollbackOutlined, OrderedListOutlined, SortDescendingOutlined, VerticalAlignBottomOutlined, DownSquareOutlined, CopyOutlined, PicCenterOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { SelectProps } from "antd/es/select";
import SplitPane from "react-split-pane";
const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Panel } = Collapse;
const axios = require("axios");
//tree begin
const { Search, TextArea } = Input;

//tree end
function getRandomInt(max, min = 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min; // eslint-disable-line no-mixed-operators
}

class App extends React.Component {
    state = {
        collapsed: false,
        traceFiles: [],
        expandedKeys: [],
        searchValue: "",
        autoExpandParent: true,
        dataList: [],
        dataListFull: [],
        gDataFull: [],
        gData: [],
        options: [],
        classTags: [],
        showComment: false,
        checkNode: {},
        checkTab: {},
        structLoading: true,
        siderWidth: 300,
        lastSiderWidth: 300,
        bottomWidth: 25,
        disableNodes: [],
        saveTraceShow: false,
        saveTraceFile: "",
        filterTraceShow: false,
        filterNum: 100,
        classSearchValue: "",
        //回滚操作
        stack: [],
        commentValue: "",
        saveCommentShow: false,
        commentTranslate: false,
        initSuccess: false,
    };
    /**************************react生命周期方法 start***************************/
    componentDidMount() {
        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                let token = localStorage.getItem("token");
                if (token == null) {
                    window.location.href = "/login.html";
                }
                config.headers.Authorization = token;
                console.log(config);
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                // console.log(response);
                return response;
            },
            function (error) {
                if (error.response.status == 401) {
                    window.location.href = "/login.html";
                }
                if (error.response.status == 403) {
                    message.error("You do not have permission to this interface");
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                return Promise.reject(error);
            }
        );
        //这个方法不能轻易删，里面有初始化状态变更
        this.refreshMenu();
    }
    /**************************react生命周期方法 end***************************/
    /**************************侧边栏相关方法 start***************************/
    refreshMenu = () => {
        let self = this;
        //获取traceFiles
        axios
            .get("/webide/files")
            .then(function (response) {
                // console.log(response);
                self.setState({
                    traceFiles: response.data,
                    initSuccess: true,
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    onFileCheck = (item, filename) => {
        let self = this;
        axios
            .get("/webide/getFile", {
                params: {
                    filename: filename,
                },
            })
            .then(function (response) {
                let gDataFull = JSON.parse(response.data.content);
                self.setState({ gDataFull, saveTraceFile: filename + parseInt(new Date().getTime() / 1000) });
                console.log(self.state.saveTraceFile);
                self.refreshTree(true);
                self.setState({ gDataFull, dataListFull: self.state.dataList });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    fileDelete = (filename) => {
        let self = this;
        axios
            .get("/webide/deleteFile", {
                params: {
                    filename: filename,
                },
            })
            .then(function (response) {
                self.refreshMenu();
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    onCollapse = (collapsed) => {
        this.setState({ collapsed });
        if (collapsed) {
            this.setState({ lastSiderWidth: this.state.siderWidth, siderWidth: 30 });
        } else {
            this.setState({ siderWidth: this.state.lastSiderWidth });
        }
    };
    tabCollapse = (key) => {
        if (key == "files") {
            this.setState({
                siderWidth: 240,
            });
        } else if (key == "struct") {
            this.setState({
                siderWidth: 400,
            });
        }
        this.setState({ collapsed: false });
    };
    /**
     * 渲染树，除此之外别的地方不应该操作gData
     * 选中文件时触发
     * 初始化树结构，节点列表，节点统计
     * @param {*} treeData
     */
    refreshTree = (isInitTag) => {
        let start = this.startTask();
        this.state.dataList = [];
        //生成查询关键字
        let treeData = JSON.parse(JSON.stringify(this.state.gDataFull));
        this.logTaskTime("parse", start);
        start = this.startTask();
        this.removeTreeNode(this.state.disableNodes, treeData);
        this.logTaskTime("removeTreeNode", start);
        start = this.startTask();
        this.generateList(treeData);
        this.logTaskTime("generateList", start);
        if (isInitTag) {
            start = this.startTask();
            //生成方法统计
            this.generateTag();
            this.setState({ disableNodes: [], stack: [] });
            this.logTaskTime("generateTag", start);
        }
        // console.log(treeData);
        this.setState({
            gData: treeData,
            structLoading: false,
        });
    };
    viewMethod = (event, nodeKeys) => {
        this.clickNode(event, nodeKeys[0]);
    };
    startTask = () => {
        return new Date().getTime();
    };
    logTaskTime = (name, start) => {
        console.log(`${name} time: ${new Date().getTime() - start}`);
    };
    changeClass = (checked, method, node) => {
        let start = this.startTask();
        let temp = this.state.classTags;
        let nodeKeys = [];
        if (node == undefined) {
            let matchCount = 0;
            temp.forEach((classObj) => {
                classObj.methods.forEach((methodObj) => {
                    if (methodObj == method) {
                        methodObj.checked = checked;
                        // console.log("match");
                        matchCount += 1;
                    }
                });
            });
            if (matchCount != 1) {
                console.log(method);
                console.log("没匹配到方法:" + matchCount);
                return;
            }
            nodeKeys.push(...method.nodeKeys);
        } else {
            node.methods.forEach((method) => {
                let matchCount = 0;
                temp.forEach((classObj) => {
                    classObj.methods.forEach((methodObj) => {
                        if (methodObj == method) {
                            methodObj.checked = checked;
                            // console.log("match");
                            matchCount += 1;
                        }
                    });
                });
                if (matchCount != 1) {
                    console.log(method);
                    console.log("没匹配到方法:" + matchCount);
                    return;
                }
                nodeKeys.push(...method.nodeKeys);
            });
            node.checked = checked;
        }
        this.setState({
            classTags: temp,
        });

        if (checked) {
            this.revertTreeNodes(nodeKeys);
        } else {
            this.disableTreeNodes(nodeKeys);
        }
        this.logTaskTime("changeClass", start);
    };
    arrayContains = (array, obj) => {
        for (let item of array) {
            if (item == obj) {
                return true;
            }
        }
        return false;
    };
    removeTreeNode = (keys, nodes) => {
        //删其节点，将子抬上来，放入其原先位置
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            //这里的判断最耗时，keys越大越耗时，太难优化了
            //这里用while是因为node会变，太坑了
            while (this.arrayContains(keys, node.key)) {
                //删除节点会导致后续遍历问题，后续节点补上
                if (node.children != undefined && node.children.length > 0) {
                    //添加其子节点到当前位置
                    nodes.splice(i, 1, ...node.children);
                } else {
                    //删除该节点
                    nodes.splice(i, 1);
                }
                if (i < nodes.length) {
                    node = nodes[i];
                } else {
                    break;
                }
            }
            if (node.children != undefined && node.children.length > 0) {
                this.removeTreeNode(keys, node.children);
            }
        }
    };

    removeTreeNode = (keys, nodes) => {
        //删其节点，将子抬上来，放入其原先位置
        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            while (this.arrayContains(keys, node.key)) {
                // debugger;
                //删除节点会导致后续遍历问题，后续节点补上
                if (node.children != undefined && node.children.length > 0) {
                    //添加其子节点到当前位置
                    nodes.splice(i, 1, ...node.children);
                } else {
                    //删除该节点
                    nodes.splice(i, 1);
                }
                if (i < nodes.length) {
                    node = nodes[i];
                } else {
                    break;
                }
            }
            if (node.children != undefined && node.children.length > 0) {
                this.removeTreeNode(keys, node.children);
            }
        }
    };
    generateTag = () => {
        if (this.state.dataList.length == 0) {
            return;
        }
        // console.log(this.state.dataList);
        let classTags = this.state.dataList
            .reduce((acc, curr) => {
                // console.log(acc);
                if (!(acc instanceof Array)) {
                    let old = acc;
                    acc = [
                        {
                            value: old.className,
                            color: "green",
                            checked: true,
                            methods: [
                                {
                                    checked: true,
                                    name: old.fullMethodName,
                                    nodeKeys: [old.key],
                                },
                            ],
                        },
                    ];
                }
                let optionData = acc.find((item) => item.value == curr.className);
                //判断类是否存在
                if (optionData == undefined) {
                    optionData = {
                        value: curr.className,
                        color: "green",
                        checked: true,
                        methods: [],
                    };
                    acc.push(optionData);
                }
                //判断方法是否存在
                let compareMethod = optionData.methods.find((item) => item.name == curr.fullMethodName);
                if (compareMethod == undefined) {
                    compareMethod = {
                        name: curr.fullMethodName,
                        checked: true,
                        nodeKeys: [],
                    };
                    optionData.methods.push(compareMethod);
                }
                compareMethod.nodeKeys.push(curr.key);
                return acc;
            })
            .filter((optionData) => {
                optionData.methods.sort((a, b) => b.nodeKeys.length - a.nodeKeys.length);
                if (optionData.methods.length == 0) {
                    optionData.total = 0;
                } else if (optionData.methods.length == 1) {
                    optionData.total = optionData.methods[0].nodeKeys.length;
                } else {
                    optionData.total = optionData.methods.reduce((acc, curr) => (typeof acc == "number" ? acc + curr.nodeKeys.length : acc.nodeKeys.length + curr.nodeKeys.length));
                }
                if (optionData.total < 5) {
                    optionData.color = "green";
                } else if (optionData.total < 10) {
                    optionData.color = "cyan";
                } else if (optionData.total < 50) {
                    optionData.color = "blue";
                } else if (optionData.total < 100) {
                    optionData.color = "geekblue";
                } else {
                    optionData.color = "purple";
                }
                return true;
            })
            .sort((a, b) => b.total - a.total);
        // console.log(classTags);
        this.setState({ classTags });
    };
    /**************************侧边栏相关方法 end***************************/

    /**************************header相关方法 start***************************/
    filterTrace = () => {
        let temp = this.state.classTags;
        for (let i = 0; i < temp.length; i++) {
            let classData = temp[i];
            for (let j = 0; j < classData.methods.length; j++) {
                let method = classData.methods[j];
                if (method.nodeKeys.length >= this.state.filterNum) {
                    this.changeClass(false, method);
                }
            }
        }
        this.setState({ filterTraceShow: false });
    };
    handleSearch = (value) => {
        // console.log("onSearch");
        this.setState({ options: value ? this.searchResult(value) : [] });
    };

    generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const { key, title, parentKey, comment } = node;
            const methodIndex = title.indexOf("(");
            const classIndex = title.lastIndexOf(".", methodIndex);
            const packageIndex = title.lastIndexOf(".", classIndex - 1);
            this.state.dataList.push({ key, title, parentKey, comment, className: title.substr(packageIndex + 1, classIndex - packageIndex - 1), fullMethodName: title.substr(classIndex + 1) });
            if (node.children) {
                this.generateList(node.children);
            }
        }
    };
    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };
    getNodeKeys = (nodes) => {
        let keys = [];
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node == undefined) {
                continue;
            }
            keys.push(node.key);
            if (node.children) {
                keys.push(...this.getNodeKeys(node.children));
            }
        }
        return keys;
    };
    getNode = (key, tree) => {
        let matchNode = undefined;
        // console.log(tree);
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            // console.log(node.key + " " + (node.key == key));
            if (node.key == key) {
                // console.log("find");
                matchNode = node;
            } else if (node.children) {
                //递归不能轻易用return，return等于结束
                matchNode = this.getNode(key, node.children);
            }

            if (matchNode != undefined) {
                break;
            }
        }
        return matchNode;
    };
    onSelect = (value) => {
        let expandedKeys = [];
        if (value != "") {
            expandedKeys = this.state.dataList
                .map((item) => {
                    if (item.title.indexOf(value) > -1) {
                        return item.parentKey;
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
        }
        console.log(expandedKeys);
        this.setState({
            expandedKeys,
            searchValue: value,
            autoExpandParent: true,
        });
    };
    searchResult = (query) => {
        let temp = this.state.dataList
            .map((item) => {
                const methodIndex = item.title.indexOf("(");
                const classIndex = item.title.lastIndexOf(".", methodIndex);
                const packageIndex = item.title.lastIndexOf(".", classIndex - 1);
                return item.title.substr(packageIndex + 1, methodIndex - packageIndex - 1);
            })
            .filter((item) => item.toLowerCase().indexOf(query.toLowerCase()) > -1);
        if (temp.length == 0) {
            return [];
        }
        let result = temp
            .reduce((acc, curr) => {
                // console.log(acc);
                if (typeof acc == "string") {
                    let old = acc;
                    acc = [
                        {
                            value: old,
                            count: 1,
                        },
                    ];
                }
                // console.log(acc);
                let optionData = acc.find((item) => item.value == curr);
                if (optionData == undefined) {
                    optionData = {
                        value: curr,
                        count: 0,
                    };
                    acc.push(optionData);
                }
                optionData.count++;
                return acc;
            })
            .sort((a, b) => b.count - a.count)
            .map((item) => {
                return {
                    value: item.value,
                    label: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span>
                                <span style={{ color: "#43c3ff" }}>{item.value}</span>
                            </span>
                            <span>{item.count} results</span>
                        </div>
                    ),
                };
            });

        // console.log(result);
        return result;
    };

    handleOk = () => {
        let self = this;
        axios
            .post("/webide/writeFile", {
                filename: this.state.saveTraceFile,
                content: JSON.stringify(this.state.gData),
            })
            .then(function (response) {
                console.log(response);
                //刷新左侧
                self.refreshMenu();
                self.setState({
                    saveTraceShow: false,
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };

    /**************************header相关方法 end***************************/
    /**************************树相关方法 start***************************/
    clickNode = (event, key) => {
        console.log("check node");
        let checkNode = this.getNode(key, this.state.gDataFull);
        const methodIndex = checkNode.title.indexOf("(");
        const classIndex = checkNode.title.lastIndexOf(".", methodIndex);
        const packageIndex = checkNode.title.lastIndexOf(".", classIndex - 1);
        const className = checkNode.title.substr(packageIndex + 1, classIndex - packageIndex - 1);
        this.setState({ checkNode, classSearchValue: className, commentValue: checkNode.comment });
        this.toggleBottomShow(undefined, true);
    };
    spreadTree = (event) => {
        const expandedKeys = this.state.dataList.map((item) => item.parentKey).filter((item, i, self) => item && self.indexOf(item) === i);
        this.setState({
            expandedKeys,
            autoExpandParent: true,
        });
    };
    shrinkTree = (event) => {
        this.setState({
            expandedKeys: [],
            autoExpandParent: true,
        });
    };
    loop = (treeData) => {
        const { searchValue } = this.state;
        return treeData.map((item) => {
            let index = -1;
            let beforeStr = "";
            let afterStr = "";
            if (searchValue != "") {
                index = item.title.indexOf(searchValue);
                beforeStr = item.title.substr(0, index);
                afterStr = item.title.substr(index + searchValue.length);
            }
            const methodIndex = item.title.indexOf("(");
            const classIndex = item.title.lastIndexOf(".", methodIndex);
            const packageIndex = item.title.lastIndexOf(".", classIndex - 1);
            const paramIndex = item.title.lastIndexOf(":");
            const packageStr = item.title.substr(0, packageIndex + 1);
            const classStr = item.title.substr(packageIndex + 1, classIndex - packageIndex);
            const methodStr = item.title.substr(classIndex + 1, methodIndex - classIndex - 1);
            const paramStr = paramIndex == -1 ? item.title.substr(methodIndex) : item.title.substr(methodIndex, paramIndex - methodIndex);
            const returnStr = paramIndex == -1 ? "" : item.title.substr(paramIndex);
            // console.log(`${packageStr}-${classStr}-${methodStr}-${paramStr}-${returnStr}`);
            // console.log(searchValue);
            const title =
                index > -1 ? (
                    <Dropdown key={item.key + "dropdown"} trigger={["contextMenu"]} overlay={this.treeNodeMenu(item)} placement="bottomCenter" arrow>
                        <span
                            title={item.comment}
                            onClick={(ev) => {
                                this.clickNode(ev, item.key);
                            }}
                        >
                            {beforeStr}
                            <span className="site-tree-search-value">{searchValue}</span>
                            {afterStr}
                        </span>
                    </Dropdown>
                ) : (
                    <Dropdown key={item.key + "dropdown"} trigger={["contextMenu"]} overlay={this.treeNodeMenu(item)} placement="bottomCenter" arrow>
                        <span
                            title={item.comment}
                            onClick={(ev) => {
                                this.clickNode(ev, item.key);
                            }}
                        >
                            {packageStr}
                            <span style={{ color: "#43c3ff" }}>{classStr}</span>
                            <span style={{ color: "#dbbe73" }}>{methodStr}</span>
                            {paramStr}
                            <span style={{ color: "#d3d59e" }}>{returnStr}</span>
                        </span>
                    </Dropdown>
                );
            if (item.children) {
                return { title, key: item.key, children: this.loop(item.children) };
            }

            return {
                title,
                key: item.key,
            };
        });
    };
    revertTreeNodes = (keys) => {
        if (keys == undefined) {
            if (this.state.stack.length < 1) {
                message.error("no revert data!");
            }
            keys = this.state.stack.pop();
        } else {
            this.state.stack = this.state.stack.filter((el) => !(keys.length == el.length && keys.every((x) => el.includes(x))));
        }
        this.state.disableNodes = this.state.disableNodes.filter((el) => !keys.includes(el));
        //刷新tree
        this.refreshTree(false);
        message.success(`revert ${keys.length} nodes`);
    };
    disableTreeNodes = (keys) => {
        this.state.disableNodes.push(...keys);
        this.state.stack.push(keys);
        // let treeData = JSON.parse(JSON.stringify(this.state.gDataFull));
        // for (let key of this.state.disableNodes) {
        //     this.removeTreeNode(key, treeData);
        // }
        // console.log(this.state.disableNodes);
        // let start = this.startTask();
        // this.removeTreeNode(this.state.disableNodes, treeData);
        // this.logTaskTime("removeTreeNode", start);
        // let start = this.startTask();
        //刷新tree
        this.refreshTree(false);
        // this.logTaskTime("refreshTree", start);
        message.success(`disable ${keys.length} nodes`);
    };
    treeNodeMenu = (node) => {
        // console.log(node.title);
        return (
            <Menu key={node.key + "treeNodeMenu"}>
                <Menu.Item
                    key={node.key + "delete"}
                    onClick={(event) => {
                        this.disableTreeNodes([node.key]);
                    }}
                >
                    删除单个
                </Menu.Item>
                <Menu.Item
                    key={node.key + "recomment"}
                    onClick={(event) => {
                        this.setState({
                            checkNode: node,
                            commentValue: node.comment,
                            saveCommentShow: true,
                        });
                    }}
                >
                    修改备注
                </Menu.Item>
                <Menu.Item
                    key={node.key + "deleteAll"}
                    onClick={(event) => {
                        let keys = this.getNodeKeys([node]);
                        this.disableTreeNodes(keys);
                    }}
                >
                    删除所有
                </Menu.Item>
                <Menu.Item
                    key={node.key + "deleteAll"}
                    onClick={(event) => {
                        let keys = this.getNodeKeys(node.children);
                        this.disableTreeNodes(keys);
                        // node.children = undefined;
                    }}
                >
                    删除子节点
                </Menu.Item>
            </Menu>
        );
    };
    /**************************树相关方法 end***************************/

    /**************************底部相关方法 start***************************/
    realCountNode = (key) => {
        if (key == undefined) {
            return 0;
        }
        return this.countNode(this.getNode(key, this.state.gData));
    };
    countNode = (node, flag) => {
        let count = 0;
        // console.log(node);
        if (node != undefined) {
            if (flag == undefined && node.key != undefined) {
                count++;
            }
            if (node.children != undefined && node.children.length > 0) {
                count += node.children.length;
                for (let child of node.children) {
                    count += this.countNode(child, 1);
                }
            }
        }
        return count;
    };
    toggleTranslate = (commentTranslate) => {
        this.setState({ commentTranslate });
    };
    modifyComment = (event) => {
        let node = this.getNode(this.state.checkNode.key, this.state.gDataFull);
        console.log(node == this.state.checkNode);
        node.comment = this.state.commentValue;
        this.setState({ saveCommentShow: false });
        this.refreshTree(false);
        // console.log(this.state.gDataFull);
        // this.setState({ gData: treeData });
    };
    toggleBottomShow = (event, isShow) => {
        let changeShow = !this.state.showComment;
        if (isShow == true) {
            changeShow = isShow;
        }
        this.setState({
            bottomWidth: changeShow ? 150 : 25,
            showComment: changeShow,
        });
    };
    /**************************底部相关方法 end***************************/

    render() {
        const { collapsed, commentTranslate, bottomWidth, commentValue, saveCommentShow, checkNode, classSearchValue, filterNum, saveTraceFile, filterTraceShow, saveTraceShow, dataList, dataListFull, expandedKeys, siderWidth, autoExpandParent, classTags, traceFiles, showComment, structLoading, initSuccess } = this.state;
        if (!initSuccess) {
            return <Skeleton active avatar paragraph={{ rows: 10 }} />;
        }
        let start = this.startTask();
        let filterClassTags = classTags;
        if (classSearchValue != "") {
            filterClassTags = filterClassTags.filter((item) => item.value.indexOf(classSearchValue) > -1);
        }
        const codeStruct = structLoading ? (
            <Skeleton active />
        ) : (
            <div>
                <Row style={{ background: "#242426", color: "#ffffff" }}>
                    <Col span={20}>
                        <Input
                            size="small"
                            value={classSearchValue}
                            onChange={(event) => {
                                this.setState({
                                    classSearchValue: event.target.value,
                                });
                            }}
                        />
                    </Col>
                    <Col span={4} style={{ textAlign: "center" }}>
                        <Space>
                            <SortDescendingOutlined
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                    classTags.sort((a, b) => (a.value > b.value ? 1 : -1));
                                    this.setState({ classTags });
                                }}
                            />
                            <OrderedListOutlined
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                    this.setState({ classTags: classTags.sort((a, b) => b.total - a.total) });
                                }}
                            />
                        </Space>
                    </Col>
                </Row>
                <Collapse>
                    {filterClassTags.map((item, i) => (
                        <Panel
                            header={
                                <div>
                                    <Checkbox key={item.value + "checkbox"} value={item.value} checked={item.checked} onChange={(ev) => this.changeClass(ev.target.checked, undefined, item)} />
                                    <Badge size="small" overflowCount={9000} count={item.total} style={{ background: "#1990ff" }} offset={[5, 0]}>
                                        <span style={{ color: "#ffffff" }}>{item.value}</span>
                                    </Badge>
                                </div>
                            }
                            key={item.value}
                            showArrow={false}
                        >
                            {item.methods.map((method, j) => (
                                <div key={item.value + method.name + "div"}>
                                    <Checkbox
                                        key={item.value + method.name + "checkbox"}
                                        value={item.value + method.name}
                                        checked={method.checked}
                                        onChange={(ev) => {
                                            this.changeClass(ev.target.checked, method);
                                        }}
                                    />
                                    <Badge size="small" overflowCount={1000} count={method.nodeKeys.length} style={{ background: "#1990ff" }} offset={[5, 0]}>
                                        <p
                                            style={{ color: "#ffffff", cursor: "pointer" }}
                                            key={item.value + method.name + "p"}
                                            onClick={(ev) => {
                                                this.viewMethod(ev, method.nodeKeys);
                                            }}
                                        >
                                            {method.name}
                                        </p>
                                    </Badge>
                                </div>
                            ))}
                        </Panel>
                    ))}
                </Collapse>
            </div>
        );

        const dropdownMenu = (key) => (
            <Menu key={key + "dropMenu"}>
                <Popconfirm
                    title={"Are you sure to delete " + key + " ?"}
                    onConfirm={(event) => {
                        this.fileDelete(key);
                    }}
                    okText="Yes"
                    cancelText="No"
                >
                    <Menu.Item key={key + "delete"}>删除</Menu.Item>
                </Popconfirm>
            </Menu>
        );
        this.logTaskTime("renderPre", start);
        start = this.startTask();
        const leftHtml = (
            <Sider style={{ height: "100%" }} collapsible collapsed={collapsed} onCollapse={this.onCollapse} collapsedWidth="30" width="100%">
                <Tabs tabBarGutter="1" size="small" defaultActiveKey="1" tabPosition="left" style={{ height: "100%", color: "#ffffff" }} onChange={this.tabCollapse}>
                    <TabPane tab={<CopyOutlined />} key="files">
                        <Menu theme="dark" mode="inline" key="basicMenu">
                            {traceFiles.sort().map((menu) => (
                                <SubMenu key={menu.name} title={menu.name}>
                                    {menu.children.sort().map((child) => (
                                        <Dropdown key={menu.name + "/" + child + "dropdown"} trigger={["contextMenu"]} overlay={dropdownMenu(menu.name + "/" + child)} placement="bottomCenter" arrow>
                                            <Menu.Item
                                                key={menu.name + "/" + child}
                                                onClick={(event) => {
                                                    this.onFileCheck(event, menu.name + "/" + child);
                                                }}
                                                style={{ userSelect: "none" }}
                                            >
                                                {child}
                                            </Menu.Item>
                                        </Dropdown>
                                    ))}
                                </SubMenu>
                            ))}
                        </Menu>
                    </TabPane>
                    <TabPane tab={<PicCenterOutlined />} key="struct">
                        {codeStruct}
                    </TabPane>
                </Tabs>
            </Sider>
        );
        this.logTaskTime("renderLeft", start);
        start = this.startTask();
        const rightHeader = (
            <Header style={{ background: "#1e1e1e", color: "#ffffff", padding: 0, height: "auto", width: "100%" }}>
                <Row style={{ marginTop: "-19px" }}>
                    <Col span={12}>
                        {/* <Search style={{ marginBottom: 8 }} placeholder="Search" onSearch={this.onSearch} /> */}
                        <AutoComplete style={{ width: "100%" }} options={this.state.options} onSelect={this.onSelect} onSearch={this.handleSearch}>
                            <Input.Search placeholder="Search" onClick={this.onSearch} />
                        </AutoComplete>
                    </Col>
                    <Col span={4}>
                        <Space align="center" style={{ width: "100%", marginTop: "7px", marginLeft: "5px" }}>
                            <Button type="primary" shape="circle" onClick={this.spreadTree} style={{ background: "#242426", color: "#1890ff", fontSize: "20px", lineHeight: "18px" }}>
                                <ArrowsAltOutlined />
                            </Button>
                            <Button type="primary" shape="circle" onClick={this.shrinkTree} style={{ background: "#242426", color: "#1890ff", fontSize: "20px", lineHeight: "18px" }}>
                                <ShrinkOutlined />
                            </Button>
                            <Button type="primary" shape="circle" onClick={() => this.setState({ filterTraceShow: true })} style={{ background: "#242426", color: "#1890ff", fontSize: "20px", lineHeight: "18px" }}>
                                F
                            </Button>
                            <Button
                                onClick={(event) => {
                                    this.revertTreeNodes();
                                }}
                                type="primary"
                                shape="circle"
                                style={{ background: "#242426", color: "#1890ff", fontSize: "20px", lineHeight: "18px" }}
                            >
                                <RollbackOutlined />
                            </Button>
                            <Button type="primary" shape="circle" onClick={() => this.setState({ saveTraceShow: true })} style={{ background: "#242426", color: "#1890ff", fontSize: "20px", lineHeight: "18px" }}>
                                <VerticalAlignBottomOutlined />
                            </Button>
                        </Space>
                        <Modal title="saveTrace" visible={saveTraceShow} onOk={this.handleOk} onCancel={() => this.setState({ saveTraceShow: false })}>
                            <Input
                                value={saveTraceFile}
                                onChange={(event) => {
                                    this.setState({ saveTraceFile: event.target.value });
                                }}
                            />
                        </Modal>
                        <Modal title="filterTrace" visible={filterTraceShow} onOk={this.filterTrace} onCancel={() => this.setState({ filterTraceShow: false })}>
                            <Input
                                value={filterNum}
                                onChange={(event) => {
                                    this.setState({ filterNum: event.target.value });
                                }}
                            />
                        </Modal>
                        <Modal title="saveComment" visible={saveCommentShow} onOk={this.modifyComment} onCancel={() => this.setState({ saveCommentShow: false })}>
                            <TextArea
                                autoSize
                                value={commentValue}
                                onChange={(event) => {
                                    this.setState({ commentValue: event.target.value });
                                }}
                            />
                        </Modal>
                    </Col>
                </Row>
            </Header>
        );
        this.logTaskTime("renderRightHeader", start);
        start = this.startTask();
        const rightContent = (
            <Content style={{ background: "#1e1e1e", color: "#ffffff", width: "100%" }}>
                <Tree style={{ background: "#1e1e1e", color: "#ffffff" }} onExpand={this.onExpand} expandedKeys={expandedKeys} autoExpandParent={autoExpandParent} treeData={this.loop(this.state.gData)} />
            </Content>
        );
        this.logTaskTime("renderRightContent", start);
        start = this.startTask();
        const rightFoot = (
            <div style={{ borderTop: "1px solid #505050", width: "100%" }}>
                <Row style={{ background: "#1e1e1e", color: "#ffffff" }}>
                    <Col span={20}>
                        {this.realCountNode(checkNode.key)}/{dataList.length}/{dataListFull.length}
                        &nbsp;&nbsp;&nbsp;&nbsp;{checkNode.title}
                    </Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                        <Switch checkedChildren="翻译" unCheckedChildren="原文" size="small" onChange={this.toggleTranslate} />
                        <DownSquareOutlined style={{ cursor: "pointer" }} onClick={this.toggleBottomShow} />
                    </Col>
                </Row>
                <div style={{ overflowY: "auto", height: "100%" }}>
                    {showComment ? (
                        <div>
                            <TextArea style={{ background: "#1e1e1e", color: "#ffffff" }} value={commentTranslate ? checkNode.commentCn : checkNode.comment} autoSize />
                        </div>
                    ) : (
                        []
                    )}
                </div>
            </div>
        );
        this.logTaskTime("renderRightFoot", start);
        return (
            <SplitPane split="vertical" paneStyle={{ background: "#242426" }} size={siderWidth}>
                {leftHtml}
                <div>
                    <SplitPane split="horizontal" paneStyle={{ background: "#1e1e1e" }} pane1Style={{ overflowY: "auto" }} size={bottomWidth} primary="second">
                        <SplitPane split="horizontal" paneStyle={{ background: "#1e1e1e" }} pane2Style={{ overflowY: "auto" }} size={36}>
                            {rightHeader}
                            {rightContent}
                        </SplitPane>
                        {rightFoot}
                    </SplitPane>
                </div>
            </SplitPane>
        );
    }
}

ReactDOM.render(<App />, document.getElementById("root"));
